import {Fragment} from 'react'
import dynamic from 'next/dynamic'
import DiscoverPlacesMenu from 'src/components/modules/discoverPlacesMenu/DiscoverPlacesMenu'
import TopBar from 'src/components/modules/globals/header/topBar/TopBar'
import HeaderProvider, {useHeaderContext} from 'src/context/HeaderProvider'

const SideBar = dynamic(
  () => import('src/components/modules/globals/header/components/SideBar'),
  {
    ssr: false,
  },
)

export default function Header() {
  return (
    <HeaderProvider>
      <Content />
    </HeaderProvider>
  )
}

function Content() {
  const {
    openSidebar,
    anchorElement,
    handleCloseMenu,
    toggleOpenSidebar,
    handleOpenMenu,
  } = useHeaderContext()

  return (
    <Fragment>
      <DiscoverPlacesMenu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleCloseMenu}
      />
      <TopBar
        open={Boolean(anchorElement)}
        onOpenDrawer={toggleOpenSidebar}
        onOpenMenu={handleOpenMenu}
      />
      <SideBar
        open={openSidebar}
        onClose={toggleOpenSidebar}
        onOpenMenu={handleOpenMenu}
      />
    </Fragment>
  )
}
