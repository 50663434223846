import {useTranslation} from 'next-i18next'
import {GuestSelectType} from 'src/types/guestSelect'
import AppTypography from 'src/components/elements/typography/AppTypography'

export default function GuestSmallItems(props: {guests: GuestSelectType}) {
  const {guests} = props

  const {t} = useTranslation('common')

  const translations: {
    [key in keyof typeof guests]: string
  } = {
    adults: `${t('adults')}s`,
    children: t('children'),
    infants: `${t('infants')}s`,
    animals: t('animals'),
  }

  return (
    <div className="w-full flex flex-row justify-start items-center gap-8">
      {(Object.keys(guests) as Array<keyof GuestSelectType>)?.map((key) => {
        const value = guests[key]

        return (
          <div key={key} className="flex flex-col items-center justify-center">
            <AppTypography variant="smallCaption" style={{fontSize: 8}}>
              {translations[key].toUpperCase()}
            </AppTypography>
            <AppTypography
              variant="smallCaption"
              className="font-bold"
              style={{fontSize: 10}}
            >
              {value}
            </AppTypography>
          </div>
        )
      })}
    </div>
  )
}
