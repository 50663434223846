import {makeStyles} from '@material-ui/core/styles'
import AppIcon from 'src/components/elements/icons/AppIcon'
import AppTypography from 'src/components/elements/typography/AppTypography'

const useFieldStyles = makeStyles(
  ({spacing}) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(1 / 2),
    },
    label: {
      color: '#0A0A0A',
      fontWeight: 500,
    },
    input: {
      color: '#627293',
      fontSize: 14,
      lineHeight: '24px',
      border: '1px solid #E4E8F1',
      padding: spacing(1, 2),
      borderRadius: spacing(12.5),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
  {index: 2},
)

export default function Field(props: {
  id?: string
  label: string
  placeholder: string
  children?: React.ReactNode
  onClick?: () => void
  value?: React.ReactNode
  showIcon?: boolean
}) {
  const {id, label, placeholder, onClick, value, showIcon = false} = props

  const classes = useFieldStyles()

  return (
    <div className={classes.container}>
      <AppTypography variant="body" className={classes.label}>
        {label}
      </AppTypography>
      <div className={classes.input} id={id} onClick={onClick}>
        {value || placeholder}
        {showIcon && <AppIcon name="chevronDown" />}
      </div>
    </div>
  )
}
